@import 'resources/fonts/stylesheet.css';


body {
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Override for AntD More Item */
.ant-dropdown .ant-dropdown-menu {
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(153, 153, 153, 0.20);

    .ant-dropdown-menu-item {
        border-radius: 6px;
        padding: 5px;
        font-family: Roboto, sans-serif;

        &:hover {
            background-color: #F2F5F9;
        }

        &:active {
            background-color: #EFF2F6;
        }

        &:disabled {
            background-color: #ffffff;
            color: #999999;
        }

    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
        background-color: #F7FBFF;
    }
}

.ant-select-dropdown {
    padding: 10px;

    .ant-select-item {
        background-color: transparent !important;
        padding: 0;

        .option-container {
            font: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            padding: 8px 10px;
            border-radius: 6px;


            span {
                overflow: hidden;
            }
        }
    }
}

.ant-select-dropdown {
    .ant-select-item-option:hover .option-container{
            background-color: #F2F5F9;
    }

    .ant-select-item-option-selected {
        .option-container {
            background-color: #EFF2F6 !important;
        }

        .select-add-icon {
            display: none;
        }
    }

    .ant-select-item-option:not(.ant-select-item-option-selected) .select-remove-icon {
        display: none;
    }
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #2D55FB;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #2D55FB;
}

.ant-picker-footer > ul > li.ant-picker-ok > button {
    background-color: #2D55FB;
    border-radius: 8px;
    color: #FFF;
}

.ant-picker-footer > ul > li.ant-picker-now > a {
    color: #2D55FB;
}
