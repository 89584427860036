@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: local('Roboto Thin'), local('Roboto-Thin'),
    url('roboto-v30-cyrillic_latin-100.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-100.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-100.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('roboto-v30-cyrillic_latin-100italic.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-100italic.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-100italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('roboto-v30-cyrillic_latin-300.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-300.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-300.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('roboto-v30-cyrillic_latin-300italic.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-300italic.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-300italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('roboto-v30-cyrillic_latin-regular.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-regular.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-regular.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('roboto-v30-cyrillic_latin-italic.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-italic.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('roboto-v30-cyrillic_latin-500.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-500.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-500.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('roboto-v30-cyrillic_latin-500italic.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-500italic.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-500italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('roboto-v30-cyrillic_latin-700.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-700.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-700.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('roboto-v30-cyrillic_latin-700italic.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-700italic.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-700italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('roboto-v30-cyrillic_latin-900.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-900.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-900.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('roboto-v30-cyrillic_latin-900italic.woff2') format('woff2'),
    url('roboto-v30-cyrillic_latin-900italic.woff') format('woff'),
    url('roboto-v30-cyrillic_latin-900italic.ttf') format('truetype');
}
